import {
  mount as originalMount,
  shallowMount as originalShallowMount,
} from "@vue/test-utils";
import i18n from "@/i18n";

export const shallowMount = (component, opts = {}) => {
  return originalShallowMount(component, { ...opts, i18n });
};

export const mount = (component, opts = {}) => {
  return originalMount(component, { ...opts, i18n });
};

export const setData = (wrapper, data, value) => {
  wrapper.setData({
    [data]: value,
  });
};

export const setProp = (wrapper, prop, value) => {
  wrapper.setProps({
    [prop]: value,
  });
};
